import { rgba } from 'polished';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const ContactInfo = styled.div `
  @media screen and (max-width: 768px) {
    grid-column: 2/4;
  }
  @media screen and (max-width: 480px) {
    grid-column: 1/3;
    margin-bottom : 20px;
  }
  h4 {
    font-weight: 700;
    font-size: 16px;
    line-height: 1.38;
    margin: 0 0 25px;
  }
`;

export const InfoItem = styled.div `
  color: ${rgba('#09131F', 0.7)};
  display: flex;
  align-items: flex-start;
  font-family: Inter, sans-serif;
  font-size: 15px;
  margin-bottom:15px;
  line-height: 28px;
  letter-spacing: -0.002em;
  i {
    color: ${rgba('#fff', 0.35)};
    margin-right: 12px;
  }
  .phone-icon {
    margin-top: 0px;
  }
  .phone-number {
    margin-bottom: 0;
  }
`;