import Screenshot1 from '../../assets/image/saasClassic/screen-1.png';

import AuthorOne from '../../assets/image/saasClassic/author-1.jpg';
import AuthorTwo from '../../assets/image/saasClassic/author-2.jpg';
import Aabida from '../../assets/image/saasClassic/testimonials/aabida.png'
import Shashank from '../../assets/image/saasClassic/testimonials/shashank.png'
import aditya from '../../assets/image/saasClassic/testimonials/aditya.png'
import simran from '../../assets/image/saasClassic/testimonials/simran.png'
import jacob from '../../assets/image/saasClassic/testimonials/jacob.png'
import rohan from '../../assets/image/saasClassic/testimonials/rohan.png'

import anirudh from '../../assets/image/saasClassic/testimonials/anirudh.png'
import taha from '../../assets/image/saasClassic/testimonials/taha.png'

import vineet from '../../assets/image/saasClassic/testimonials/vineet.png'
import drashya from '../../assets/image/saasClassic/testimonials/drashya.png'
import ruchi from '../../assets/image/saasClassic/testimonials/ruchi.png'
import ishita from '../../assets/image/saasClassic/testimonials/ishita.png'

import lakshay from '../../assets/image/saasClassic/testimonials/lakshay.png'
import abhinav from '../../assets/image/saasClassic/testimonials/abhinav.png'
import lizabeth from '../../assets/image/saasClassic/testimonials/lizabeth.png'

import faiz from '../../assets/image/saasClassic/testimonials/faiz.png'
import jaseem from '../../assets/image/saasClassic/testimonials/jaseem.png'
import aaranv from '../../assets/image/saasClassic/testimonials/aaranv.png'

import rahul from '../../assets/image/saasClassic/testimonials/rahul.png'
import nidhi from '../../assets/image/saasClassic/testimonials/nidhi.png'
import dhruv from '../../assets/image/saasClassic/testimonials/dhruv.png'

import harshini from '../../assets/image/saasClassic/testimonials/harshini.png'

import AuthorThree from '../../assets/image/saasClassic/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { ic_settings } from 'react-icons-kit/md/ic_settings';
import { pieChart } from 'react-icons-kit/icomoon/pieChart';
import { briefcase } from 'react-icons-kit/fa/briefcase';

import icon1 from '../../assets/image/saasClassic/1.png';
import icon2 from '../../assets/image/saasClassic/2.png';
import icon3 from '../../assets/image/saasClassic/3.png';
import icon4 from '../../assets/image/saasClassic/4.png';

import school1 from '../../assets/image/School Logos/download 1.png'
import school2 from '../../assets/image/School Logos/download 2.png'
import school3 from '../../assets/image/School Logos/Frame 34827.png'
import school4 from '../../assets/image/School Logos/unnamed 1.png'
import school5 from '../../assets/image/School Logos/logo 6.png'
import school6 from '../../assets/image/School Logos/AVS-LOGO 1.png'
import school7 from '../../assets/image/School Logos/Lotus-Valley-International-School-Gurugram 1.png'
import school8 from '../../assets/image/School Logos/gd-goenka-LOGO 1.png'
import school9 from '../../assets/image/School Logos/Frame 34828.png'

import JACKPOTIMG from '../../assets/image/saasClassic/jackpot.svg';
import serviceItem1 from '../../assets/image/Section 2/image 16.png'
import serviceItem2 from '../../assets/image/Section 2/image 17.png'
import serviceItem3 from '../../assets/image/Section 2/image 18.png'

import featureIcon1 from '../../assets/image/Section 3/image 19.png'
import featureIcon2 from '../../assets/image/Section 3/image 20.png'
import featureIcon3 from '../../assets/image/Section 3/image 19.png'
import featureIcon4 from '../../assets/image/Section 3/image 21.png'
import featureIcon5 from '../../assets/image/Section 3/image 22.png'
import featureIcon6 from '../../assets/image/Section 3/image 19.png'
import featureIcon7 from '../../assets/image/Section 3/image 23.png'
import featureIcon8 from '../../assets/image/Section 3/image 24.png'
import featureIcon9 from '../../assets/image/Section 3/image 19.png'

import benefit1 from '../../assets/image/saasClassic/benefits/benefit1.png'
import benefit2 from '../../assets/image/saasClassic/benefits/benefit2.png'
import benefit3 from '../../assets/image/saasClassic/benefits/benefit3.png'
import benefit4 from '../../assets/image/saasClassic/benefits/benefit4.png'

import association1 from '../../assets/image/Association Logos/logo-1.png';
import association2 from '../../assets/image/Association Logos/logo-2.png';
import association3 from '../../assets/image/Association Logos/logo-3.png';
import association4 from '../../assets/image/Association Logos/logo-4.png';
import association5 from '../../assets/image/Association Logos/logo-5.png';

import jcLogo from '../../assets/image/jitinChawlaLogo.png';

export const FOOTER_DATA = {
    logo: jcLogo,
    menu: [
        {
            link: '#',
            label: 'Support',
        },
        {
            link: '#',
            label: 'About Us',
        },
        {
            link: '#',
            label: 'Privacy',
        },
        {
            link: '#',
            label: 'Contact',
        },
    ],
    social: [
        {
            icon: jcLogo,
            link: '#',
        },
    ],
};

export const MENU_ITEMS = [{
    label: 'Home',
    path: '#banner_section',
    offset: '0',
},
{
    label: 'Screenshot',
    path: '#screenshot_section',
    offset: '0',
},
{
    label: 'Feature',
    path: '#feature_section',
    offset: '0',
},
{
    label: 'Pricing',
    path: '#pricing_section',
    offset: '0',
},
{
    label: 'Testimonial',
    path: '#testimonial_section',
    offset: '0',
},
];

export const SERVICE_ITEMS = [{
    icon: serviceItem1,
    title: <><strong>20,000+</strong> workshops in different educational Institutions & corporate.</>,
},
{
    icon: serviceItem2,
    title: <>More than <strong>900 workshops, 11-12</strong>  principles & counselors meets annually</>,
},
{
    icon: serviceItem3,
    title: <><strong>20-22</strong> career fairs &<strong> 6000 – 6500</strong> aptitude testing and counseling every year.</>,
},
];

export const SCHOOLS = [{
    icon: 'school1',
    title: 'Identify ',
},
{
    icon: 'school2',
    title: 'Get ',
},
{
    icon: 'school3',
    title: 'Experience',
},
];

export const MONTHLY_PRICING_TABLE = [{
    name: 'Basic Account',
    description: 'For Small teams or group who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'Start for free',
    url: '#',
    listItems: [{
        content: 'Drag & Drop Builder',
    },
    {
        content: '1,000s of Templates Ready',
    },
    {
        content: 'Blog Tools',
    },
    {
        content: 'eCommerce Store ',
    },
    {
        content: '30+ Webmaster Tools',
    },
    ],
},
{
    name: 'Business Account',
    description: 'For Mediums teams or group who need to build website ',
    price: '$9.87',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [{
        content: 'Drag & Drop Builder',
    },
    {
        content: '1,000s of Templates Ready',
    },
    {
        content: 'Blog Tools',
    },
    {
        content: 'eCommerce Store ',
    },
    {
        content: '30+ Webmaster Tools',
    },
    ],
},
{
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$12.98',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [{
        content: 'Drag & Drop Builder',
    },
    {
        content: '1,000s of Templates Ready',
    },
    {
        content: 'Blog Tools',
    },
    {
        content: 'eCommerce Store ',
    },
    {
        content: '30+ Webmaster Tools',
    },
    ],
},
];

export const YEARLY_PRICING_TABLE = [{
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'Start for free',
    url: '#',
    listItems: [{
        content: 'Drag & Drop Builder',
    },
    {
        content: '1,000s of Templates Ready',
    },
    {
        content: 'Blog Tools',
    },
    {
        content: 'eCommerce Store ',
    },
    {
        content: '30+ Webmaster Tools',
    },
    ],
},
{
    name: 'Business Account',
    description: 'For Small teams or group who need to build website ',
    price: '$6.00',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [{
        content: 'Unlimited secure storage',
    },
    {
        content: '2,000s of Templates Ready',
    },
    {
        content: 'Blog Tools',
    },
    {
        content: '24/7 phone support',
    },
    {
        content: '50+ Webmaster Tools',
    },
    ],
},
{
    name: 'Premium Account',
    description: 'For Large teams or group who need to build website ',
    price: '$9.99',
    priceLabel: 'Per month & subscription yearly',
    buttonLabel: 'Register Now',
    url: '#',
    trialButtonLabel: 'Or Start 14 Days trail',
    trialURL: '#',
    listItems: [{
        content: 'Drag & Drop Builder',
    },
    {
        content: '3,000s of Templates Ready',
    },
    {
        content: 'Advanced branding',
    },
    {
        content: 'Knowledge base support',
    },
    {
        content: '80+ Webmaster Tools',
    },
    ],
},
];

export const FOOTER_WIDGET = [{
    title: 'About Us',
    menuItems: [{
        url: '#',
        text: 'Support Center',
    },
    {
        url: '#',
        text: 'Customer Support',
    },
    {
        url: '#',
        text: 'About Us',
    },
    {
        url: '#',
        text: 'Copyright',
    },
    {
        url: '#',
        text: 'Popular Campaign',
    },
    ],
},
{
    title: 'Our Information',
    menuItems: [{
        url: '#',
        text: 'Return Policy',
    },
    {
        url: '#',
        text: 'Privacy Policy',
    },
    {
        url: '#',
        text: 'Terms & Conditions',
    },
    {
        url: '#',
        text: 'Site Map',
    },
    {
        url: '#',
        text: 'Store Hours',
    },
    ],
},
{
    title: 'My Account',
    menuItems: [{
        url: '#',
        text: 'Press inquiries',
    },
    {
        url: '#',
        text: 'Social media directories',
    },
    {
        url: '#',
        text: 'Images & B-roll',
    },
    {
        url: '#',
        text: 'Permissions',
    },
    {
        url: '#',
        text: 'Speaker requests',
    },
    ],
},
{
    title: 'Policy',
    menuItems: [{
        url: '#',
        text: 'Application security',
    },
    {
        url: '#',
        text: 'Software principles',
    },
    {
        url: '#',
        text: 'Unwanted software policy',
    },
    {
        url: '#',
        text: 'Responsible supply chain',
    },
    ],
},
];

export const FEATURES = [{
    icon: featureIcon1,
    title: 'App Development',
    description:
        (
            <>
                Turn <strong>interests</strong> into rewarding academic and career paths.
            </>
        ),
},
{
    icon: featureIcon2,
    title: '10 Times Award',
    description: (
        <>
            Gain expert career guidance in <strong> one-on-one counseling sessions.</strong>
        </>
    ),
},
{
    icon: featureIcon4,
    title: 'Cloud Storage',
    description: (
        <>
            Uncover <strong>abilities for 90%</strong>  more accurate <strong>career planning.</strong>
        </>
    )
},
{
    icon: featureIcon5,
    title: 'Customization',
    description: (
        <>
            Personalize your academic journey with unique  <strong>personality</strong> insights.
        </>
    ),
},
{
    icon: featureIcon7,
    title: 'UX Planning',
    description: (
        <>
            Match talents with ideal careers across <strong>16 job families.</strong>
        </>
    ),
},
{
    icon: featureIcon8,
    title: 'Customer Support',
    description: (
        <>
            Bridge skills gaps for a <strong>75% increase</strong> in career readiness.
        </>
    ),
},
];

export const SCREENSHOTS = [{
    icon: ic_monetization_on,
    title: 'Budget Overview',
    image: Screenshot1,
},
{
    icon: ic_settings,
    title: 'Create & Adjust',
    image: Screenshot1,
},
{
    icon: pieChart,
    title: 'View Reports',
    image: Screenshot1,
},
{
    icon: briefcase,
    title: 'Integrations',
    image: Screenshot1,
},
];

export const TESTIMONIALS = [{
    title: 'Modern look & trending design',
    review: "Mr. Jitin Chawla & his team have significantly contributed in my success to gaining admission at King’s starting from the right beginning, since tenth standard",
    name: 'Dipali Mathur',
    designation: 'Fall Intake 2021, King\'s College London',
},
{
    title: 'Modern look & trending design',
    review: "I took up Humanities in Grade 12 at Lotus Valley. I never realised that I could study a course combination that involved both Law plus International Relations! I was convinced that my options were limited because of my background in humanities. The Center for Career Development allowed me to expand my options and procure admission into ANU, University of Melbourne, University of Sydney, University of Queensland and Macquarie University. A big thanks to Jitin sir specifically for showing me that path.",
    name: 'Apoorva Singh',
    designation: 'Lotus Valley School',
},
{
    title: 'Modern look & trending design',
    review: "After completing my 12th, I had no idea on what to pursue for my higher education. I was very confused. The Center gave me exposure and helped me make the right choice.",
    name: 'Sayan Hrik',
    designation: 'Father Agnel School',
},
{
    title: 'Modern look & trending design',
    review: "It was an absolutely great experience at Jitin Chawla’s Centre for Career Development. I got to know about it through the webinars that were conducted by my school. Before attending the session, I was not at all sure about what I will do in future, what course I will persue but through the counselling, now I know what course I will take up... The counselling session with Akshaiy sir was very informative and helpful. He helped me list out different types of courses on the basis of my interest. For someone like me who was confused about future, this session is just for you!",
    name: 'Tanvi Rautela',
    designation: '',
},
{
    title: 'Modern look & trending design',
    review: "My experience at the Center for Career Development was fantastic. They helped me through all my questions. They are always willing to help and answer all your questions!!",
    name: 'Jhanvi Manocha',
    designation: 'St. Thomas’ Girls Senior Secondary School',
},
{
    title: 'Modern look & trending design',
    review: "My experience with Jitin Chawla's Centre for Career Development was very nice . I wanted to share my appreciation for your support and guidance over the past few months . My career choice was a big decision I had difficulty making, but your help in matching my skills with the position I chose was essential. I am thankful i met someone like Akshay sir during this time . I would like to say thank you for your advice. You helped me and been supportive .Please accept my gratitude for your continued support and guidance.I sincerely appreciate the time you spent with me in counseling. Thank you for your patience guidance.",
    name: 'Aarju Yadav',
    designation: '',
},
];

export const faq = [{
    id: 1,
    expend: true,
    title: 'What is the purpose of this career assessment?',
    description: 'The purpose of this career assessment is to help you understand your interests, personality, and aptitudes to identify the most suitable career paths. By providing personalized insights, the assessment guides you in making informed academic and career decisions.',
},
{
    id: 2,
    title: 'How does the assessment work? ',
    description: 'The assessment evaluates your responses to a series of questions designed to measure your interests, personality traits, and aptitudes. Based on your answers, the assessment generates a detailed report highlighting your top four career clusters and provides recommendations for further exploration with a career counselor. ',
},
{
    id: 3,
    title: 'What makes this assessment different from other career assessments?',
    description: 'Our assessment stands out for being comprehensive, straightforward, and easy to understand. Specifically designed for Indian students, it takes into account the unique educational and career context in India. You don\'t need any preparation to take the assessment, as it focuses on measuring your innate capabilities and interests ',
},
{
    id: 4,
    title: 'How trustworthy is this assessment?',
    description: 'Our assessment is crafted and reviewed by industry experts, ensuring its credibility. Each parameter and question is meticulously designed following extensive research, which enhances the accuracy and relevance of the results. The assessment maintains strong content and expert validity, providing valuable insights for your career planning.',
},
]

export const whyChooseUs = [{
    id: 1,
    icon: icon1,
    title: 'Create Free Account',
    text: `As a delivery driver, you'll make reliable money—working anytime, anywhere.`,
    linkLabel: 'Start Earning',
    link: '#',
},
{
    id: 2,
    icon: icon2,
    title: 'Monitor user Analytics',
    text: `Grow your business and reach new customers with us.`,
    linkLabel: 'Sign up your store',
    link: '#',
},
{
    id: 3,
    icon: icon3,
    title: 'Safe & Trusted',
    text: `Get the best DoorDash experience with live order tracking.`,
    linkLabel: 'Get the app',
    link: '#',
},
{
    id: 4,
    icon: icon4,
    title: 'Fast Customer Support',
    text: `Get the best DoorDash experience with live order tracking.`,
    linkLabel: 'Learn More',
    link: '#',
},
];

export const SCALABLE_FEATURE = [{
    image: benefit1,
    title: 'IMPROVE EFFICIENCY',
    des: 'Focus your efforts on areas that matter most, saving you time and energy.',
},
{
    image: benefit2,
    title: 'MAKE INFORMED DECISIONS',
    des: 'Understand where you currently stand and choose a career path based on your unique strengths and abilities.',
},
{
    image: benefit3,
    title: 'PERSONALIZED GROWTH',
    des: 'Receive guidance on how to develop and refine the skills needed for success in your chosen field.',
},
{
    image: benefit4,
    title: 'COMPETITIVE EDGE',
    des: 'Stand out from the crowd by leveraging your strengths effectively.',
},
];

export const howTos = [{
    id: 1,
    icon: school1,
    text: `Birla School Doha`,
},
{
    id: 2,
    icon: school2,
    text: `FAPS Kuwait`,
},
{
    id: 3,
    icon: school3,
    text: `Indian School Oman`,
},
{
    id: 4,
    icon: school4,
    text: `Indian Central School Kuwait`,
},
{
    id: 5,
    icon: school5,
    text: `DPS RK Puram`,
},
{
    id: 5,
    icon: school5,
    text: `DPS Mathura Road`,
},
{
    id: 5,
    icon: school5,
    text: `DPS Greater Noida`,
},
{
    id: 6,
    icon: school6,
    text: `Assam Valley`,
},
{
    id: 7,
    icon: school7,
    text: `Lotus Valley International School`,
},
{
    id: 8,
    icon: school8,
    text: `GD Goenka World Schools`,
},
{
    id: 8,
    icon: school9,
    text: `Suncity World School`,
},
];


export const association = [{
    id: 1,
    icon: association1,
},
{
    id: 2,
    icon: association2,
},
{
    id: 3,
    icon: association3,
},
{
    id: 4,
    icon: association4,
},
{
    id: 5,
    icon: association5,
},
];


export const contactInfo = {
    contactInfo: {
        title: 'Need help? We are here for you',
        phone: `+91-9958321112 `,
        email: `guidance@jitinchawla.com`,
    },
};