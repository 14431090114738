import React from 'react';
import Container from '../../common/components/UI/Container';
import { Image } from 'antd';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Section, { SectionHeading, Grid, Item } from './school.style';

import { howTos } from '../../common/data/SaasClassic';

const SchoolSection = () => {
  return (
    <Section id="how-to">
      <Container width="1600px">
        <SectionHeading>
          <Heading content="Meet the Trailblazing Institutions That Have Already Joined the Journey!" />
        </SectionHeading>
        <Grid>
          { howTos.map( ( howTo ) => (
            <Item key={ howTo.id }>
              <figure>
                <Image src={ howTo.icon } alt="icon" preview={ false } />
              </figure>
              {/*<Heading as="h4" content={howTo.title} />*/ }
              <Text content={ howTo.text } />
              {/*<Link href={howTo.link}>
                {howTo.linkLabel} <Icon icon={arrowRight} />
              </Link>*/}
            </Item>
          ) ) }
        </Grid>
      </Container>
    </Section>
  );
};

export default SchoolSection;
