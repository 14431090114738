import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import FeatureBlock from '../../common/components/FeatureBlock';
import Container from '../../common/components/UI/Container';
import Button from '../../common/components/Button';

import SectionWrapper from './feature.style';
import { FEATURES } from '../../common/data/SaasClassic';
import { Image } from 'antd';

const FeatureSection = ( {
  secTitleWrapper,
  secText,
  secHeading,
  row,
  col,
  FeatureItemStyle,
  iconStyle,
  contentStyle,
  featureTitle,
  buttonWrapper,
  btnStyle,
  outlineBtnStyle,
  featureDescription,
} ) => {

  const downloadPDF = () => {
    window.open('https://stride-survey-assets.s3.amazonaws.com/jitinChawla/jitinchawlasamplereport');
  };

  return (
    <SectionWrapper id="feature_section">
      <Container>
        <Box { ...secTitleWrapper }>
          <Text { ...secText } content="But why take the assessment? " />
          <Heading
            { ...secHeading }
            content="Over 50% of students feel unprepared for career choices. But it doesn’t have to be this way. Utilizing a blend of career profiling, gap analysis, and stream alignment, our report aligns students with the right academic streams and career paths."
          />
        </Box>

        <Box { ...row }>
          { FEATURES.map( ( item, index ) => (
            <Box { ...col } key={ `feature-item-${ index }` } className="feature_col">
              <FeatureBlock
                icon={
                  <Image
                    src={ item.icon }
                    alt={ `feature-item-icon-${ index + 1 }` }
                    preview={ false }
                  />
                }
                wrapperStyle={ FeatureItemStyle }
                iconStyle={ iconStyle }
                contentStyle={ contentStyle }
                iconPosition="left"
                //title={ <Heading content={ item.title } { ...featureTitle } /> }
                description={
                  <Text content={ item.description } { ...featureDescription } />
                }
              />
            </Box>
          ) ) }
        </Box>
        {/*<Box { ...buttonWrapper }>
          <Button title="Download Sample Report" { ...btnStyle } />
        </Box>*/}
        <Box { ...buttonWrapper }>
          <Button onClick={ downloadPDF } title="Download Sample Report" { ...btnStyle } />
        </Box>
      </Container>
    </SectionWrapper>
  );
};

FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  FeatureItemStyle: PropTypes.object,
  iconStyle: PropTypes.object,
  buttonWrapper: PropTypes.object,
  btnStyle: PropTypes.object,
  outlineBtnStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

FeatureSection.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '30px',
    fontWeight: '800',
    color: '#000000',
    mb: '12px',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: ['13px', '14px'],
    fontWeight: '500',
    colors: '#F1585A',
    pl: ['15px', '30px'],
    pr: ['15px', '30px'],
  },
  outlineBtnStyle: {
    minWidth: '156px',
    fontSize: '16px',
    fontWeight: '500',
    color: '#F1585A',
    colors: '#F1585A',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['15px', '18px', '20px', '18px'],
    fontWeight: '500',
    color: '#0f2137',
    mt: '10px',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.5',
    maxWidth: ['90%', '90%', '80%', '70%', '70%'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-30px', '-30px', '-30px', '-25px', '-30px'],
    mr: ['-30px', '-30px', '-30px', '-25px', '-45px'],
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pl: ['30px', '30px', '30px', '25px', '45px'],
    pr: ['30px', '30px', '30px', '25px', '45px'],
    mb: ['50px', '70px'],
  },
  FeatureItemStyle: {},
  iconStyle: {
    width: ['72px', '72px', '72px', '72px', '82px'],
    height: ['72px', '72px', '72px', '72px', '82px'],
    mr: '20px',
    borderRadius: ['20px', '20px', '20px', '20px', '28px'],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    bg: '##FFEEEE',
    color: '##FFEEEE',
    fontSize: ['26px', '26px', '26px', '26px', '30px'],
  },
  featureTitle: {
    fontSize: ['17px', '19px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.020em',
    lineHeight: '1.5',
    mb: ['10px', '13px'],
  },
  featureDescription: {
    fontSize: '17px',
    fontWeight: '400',
    color: '#000000',
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: 0,
  },
};

export default FeatureSection;
