import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import SectionWrapper from './service.style';
import { SERVICE_ITEMS } from '../../common/data/SaasClassic';
import { Image } from 'antd';

const ServiceSection = ( {
  secTitleWrapper,
  secText,
  secHeading,
  secHeadingOne,
  Row,
  Col,
  serviceTitleStyle,
  secDes,
} ) => {
  return (
    <SectionWrapper>
      <Container>
        <Box { ...secTitleWrapper }>
          <Heading
            { ...secHeadingOne }
            content="ABOUT US"
          />
          <Heading
            { ...secHeading }
            content="Jitin Chawla Centre for Career Development "
          />
          <Text
            { ...secDes }
            content={ <>Discover Your Path with <span style={ { color: '#F1585A' } }>North India's Top Career Counseling Centre!</span> At Jitin Chawla's Centre For Career Development, we've transformed futures at  <span style={ { color: '#F1585A' } }>over 1,500 schools</span> through <span style={ { color: '#F1585A' } }>1.5 million assessments</span>  over <span style={ { color: '#F1585A' } }>23 years.</span> Engage with the minds behind workshops in top-tier schools like<span style={ { color: '#F1585A' } }> DPS RK Puram and GD Goenka.</span> Our <span style={ { color: '#F1585A' } }>annual 900+ workshops </span> and prominent <span style={ { color: '#F1585A' } }>media presence on NDTV and Times of India </span> have set new benchmarks in career guidance. Ready for a change? Join us and embark on a journey to career excellence!</> }
          />
        </Box>

        <Box { ...Row }>
          { SERVICE_ITEMS.map( ( item, index ) => (
            <Box
              { ...Col }
              key={ `service-item-${ index }` }
              className="service_item_col"
            >
              <Box className="service_item">
                <Box className="service_icon">
                  <Image src={ item.icon } alt="icon" />
                </Box>
                <Heading as="h3" content={ item.title } { ...serviceTitleStyle } />
              </Box>
            </Box>
          ) ) }
        </Box>
      </Container>
    </SectionWrapper>
  );
};

ServiceSection.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
  Row: PropTypes.object,
  Col: PropTypes.object,
  serviceTitleStyle: PropTypes.object,
  secDes: PropTypes.object,
  secHeadingOne: PropTypes.object,
};

ServiceSection.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#F1585A',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '34px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '700px',
    maxWidth: '100%',
  },
  secHeadingOne: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '17px'],
    fontWeight: '700',
    color: '#F1585A',
    letterSpacing: '0.15em',
    mb: '10px',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '540px',
    maxWidth: '100%',
  },
  Row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    ml: ['0', '-15px', '-20px', '-35px', '-35px'],
    mr: ['0', '-15px', '-20px', '-35px', '-35px'],
  },
  Col: {
    pl: ['15px', '15px', '25x', '35px', '35px'],
    pr: ['15px', '15px', '25px', '35px', '35px'],
    mb: '40px',
  },
  serviceTitleStyle: {
    fontSize: ['15px', '15px', '15px', '16px', '17px'],
    fontWeight: '500',
    color: '#ff4361',
    letterSpacing: '-0.025em',
    lineHeight: '1.5',
    mb: 0,
  },
  secDes: {
    fontSize: ['16px', '17px', '18px'],
    fontWeight: '600',
    color: '#5D646D',
    lineHeight: '1.875',
    mt: '30px',
    ml: 'auto',
    mr: 'auto',
    maxWidth: '100%',
    textAlign: 'center',
  },
};

export default ServiceSection;
