import React, { useState } from 'react';
import Container from '../../common/components/UI/Container';
import Text from '../../common/components/Text';
import Section, { Content } from './disclaimer.style';

const Disclaimer = () => {

  return (
    <Section>
      <Container>
        <Content>
          <Text content={ <><strong>Disclaimer : </strong>Please note that this site is not affiliated, associated, authorized, endorsed by, or in any way officially connected with any of the social media platforms including LinkedIn™, Google™, Meta™, or any of their subsidiaries or affiliates. The official websites of these platforms can be found at their respective URLs.</> } />
        </Content>
      </Container>
    </Section>
  );
};

export default Disclaimer;
