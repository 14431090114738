import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import { openModal, closeModal } from '@redq/reuse-modal';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import Button from '../../common/components/Button';
import Container from '../../common/components/UI/Container';
import TiltShape from '../TiltShape';
import {
  BannerWrapper,
  DiscountWrapper,
  DiscountLabel,
  VideoModal,
  PlayButton,
  VideoWrapper,
} from './banner.style';
import BannerImage from '../../common/assets/image/Frame 34837.png';
import LogoImage from '../../common/assets/image/jitinChawlaLogo.png'
import backgroundImage from '../../common/assets/image/saasClassic/background.png'
import { Image } from 'antd';

// close button for modal
const CloseModalButton = () => (
  <Button
    className="modalCloseBtn"
    variant="fab"
    onClick={ () => closeModal() }
    icon={ <i className="flaticon-plus-symbol" /> }
  />
);

const ModalContent = () => (
  <VideoWrapper>
    <iframe
      title="Video"
      src="https://www.youtube.com/embed/8ME-QAlW6Ww"
      frameBorder="0"
    />
  </VideoWrapper>
);

const BannerSection = ( {
  row,
  contentWrapper,
  discountAmount,
  discountText,
  title,
  description,
  logoImage,
  imageWrapper,
  buttonWrapper,
  descriptionThree,
  button,
  scholarshipText,
  fillButton,
  descriptionTwo,
} ) => {
  // modal handler
  const handleVideoModal = () => {
    openModal( {
      config: {
        className: 'video-modal',
        disableDragging: true,
        default: {
          width: '100%',
          height: '100%',
          x: 0,
          y: 0,
        },
      },
      component: ModalContent,
      componentProps: {},
      closeComponent: CloseModalButton,
      closeOnClickOutside: true,
    } );
  };

  const handleProceedToTest = () => {
    window.open( 'https://assessments.jitinchawla.com/jc/test/signup', '_blank' );
  }

  return (
    <BannerWrapper id="banner_section">
      <TiltShape className="banner-shape" />
      <Container>
        <Box { ...row }>
          <Box { ...contentWrapper }>
            <DiscountWrapper>
              {/*<DiscountLabel>*/ }
              <Image
                src={ LogoImage }
                alt="logoImage"
              />
              {/*<Text { ...discountAmount } content="25% Save" />
                <Text
                  { ...discountText }
                  content="for first month trail version"
                />*/}
              {/*</DiscountLabel>*/ }
            </DiscountWrapper>
            <Text
              { ...descriptionThree }
              content="JC Stream and Career Assessment Test"
            />
            <Heading
              { ...title }
              content="What If You Could Decode Your Future Career Path Today?"
            />
            <Text
              { ...description }
              content="Every child deserves a career that they are truly meant for. Uncover your true calling with our comprehensive psychometric assessment, offering a personalized ‘Career Match’ through a 360-degree analysis of your personality, interests, and aptitude."
            />
            <Text
              { ...descriptionTwo }
              content="Start the assessment now and get the career you are destined for"
            />
            <Box { ...buttonWrapper }>

              <Button { ...fillButton } title="Start Your Assessment!" onClick={ handleProceedToTest } />

            </Box>
          </Box>
          <Box { ...imageWrapper }>
            <Fade bottom>
              <Image src={ BannerImage } alt="banner image" preview={ false } />
            </Fade>
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  contentWrapper: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  title: PropTypes.object,
  logoImage: PropTypes.object,
  description: PropTypes.object,
  descriptionTwo: PropTypes.object,
  descriptionThree: PropTypes.object,
  scholarshipText: PropTypes.object,
  imageWrapper: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
  fillButton: PropTypes.object,
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    width: ['100%', '100%', '80%', '55%', '70%'],
    mb: '40px',
  },
  title: {
    fontSize: ['24px', '32px', '40px', '42px', '42px'],
    fontWeight: '700',
    color: '#00000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px', '25px', '25px', '25px'],
    lineHeight: '1.2',
    textAlign: 'center',
  },
  logoImage: {
    textAlign: 'center',
  },
  description: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#00000',
    fontWeight: '500',
    lineHeight: '1.75',
    mb: '0',
    textAlign: 'center',
  },
  descriptionTwo: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#F1585A',
    fontWeight: '700',
    lineHeight: '1.75',
    mt: '1rem',
    textAlign: 'center',
  },
  descriptionThree: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#00000',
    fontWeight: '700',
    lineHeight: '1.75',
    mt: '0.5rem',
    mb: '1.5rem',
    textAlign: 'center',
  },
  scholarshipText: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: '#f57f23',
    lineHeight: '1.75',
    mb: '0',
    mt: '35px',
    textAlign: 'center',
  },
  discountAmount: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '700',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
  },
  discountText: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    fontWeight: '400',
    color: '#fff',
    mb: 0,
    as: 'span',
  },
  fillButton: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '700',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#FFFFFF',
    height: '46px',
    minWidth: ['auto', '150px'],
    height: ['40px', '46px'],
    minHeight: 'auto',
  },
  buttonWrapper: {
    flexBox: true,
    justifyContent: 'center',
    mt: '35px',
  },
  button: {
    type: 'button',
    fontSize: ['13px', '14px'],
    fontWeight: '600',
    borderRadius: '4px',
    p: ['0px 15px', '8px 22px'],
    color: '#fff',
    colors: 'blackBackground',
    height: ['40px', '46px'],
    minHeight: 'auto',
    ml: '20px',
  },
};

export default BannerSection;
