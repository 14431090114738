import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { saasClassicTheme } from '../common/theme/saasClassic';

import ResetCSS from '../common/assets/css/style';
import BannerSection from "../components/Banner/index";
import FeatureSection from "../components/Feature";
import ServiceSection from "../components/Service";
import PartnerSection from "../components/Partner";
import TestimonialSection from "../components/Testimonial";
//import TrialSection from "../components/Trial";
import Newsletter from "../components/Newsletter";
import { GlobalStyle, ContentWrapper } from './landingPage.style';
import ScalableHistory from '../components/ScalableSection';
import FaqSection from '../components/FaqSection';
import SchoolSection from '../components/SchoolSection';
import Association from '../components/Association';
import Disclaimer from '../components/Disclaimer';
import Footer from '../components/Footer';

const JitinChawla = () => {
  return (
    <ThemeProvider theme={ saasClassicTheme }>
      <Fragment>
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          {/*<Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>*/}
          <BannerSection />
          <ServiceSection />
          {/*<UpdateScreen />*/ }
          <FeatureSection />
          {/*<ScalableHistory />*/ }
          <PartnerSection />
          {/*<PricingSection />*/ }
          <TestimonialSection />
          <SchoolSection />
          {/*<School/>*/ }
          <FaqSection />
          <Association />
          {/*<TrialSection />*/ }
          <Newsletter />
          <Disclaimer />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
}

export default JitinChawla;